<template>
  <div>
    <component :is="activeSlug" v-if="activeSlug !== 'not-found'" />

    <div v-if="activeSlug === 'not-found'" class="text-center mt-4">
      <h3>oops! Submission not found. :(</h3>
      <p class="mt-2">Please check internet connection or contact technician!</p>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { onUnmounted } from '@vue/composition-api'

const EinCCorp = () => import('./templates/EinCCorp.vue')
const EinSCorp = () => import('./templates/EinSCorp.vue')
const EinChurchOrganization = () => import('./templates/EinChurchOrganization.vue')
const EinEstateOfDeceased = () => import('./templates/EinEstateOfDeceased.vue')
const EinIndividualSoleProprietorship = () => import('./templates/EinIndividualSoleProprietorship.vue')
const EinTrust = () => import('./templates/EinTrust.vue')
const EinLimitedLiabilityCompany = () => import('./templates/EinLimitedLiabilityCompany.vue')
const EinPersonalServiceCorporation = () => import('./templates/EinPersonalServiceCorporation.vue')
const EinPartnership = () => import('./templates/EinPartnership.vue')
const EinNonProfitOrganization = () => import('./templates/EinNonProfitOrganization.vue')

const FilingApostilleCertificate = () => import('./templates/FilingApostilleCertificate.vue')
const FilingCertifiedCopies = () => import('./templates/FilingCertifiedCopies.vue')
const FilingCertificateOfGoodStanding = () => import('./templates/FilingCertificateOfGoodStanding.vue')
const FilingDigitalCorporateKit = () => import('./templates/FilingDigitalCorporateKit.vue')
const FilingDissolution = () => import('./templates/FilingDissolution.vue')
const FilingEntityConversion = () => import('./templates/FilingEntityConversion.vue')
const FilingEntityWithdrawal = () => import('./templates/FilingEntityWithdrawal.vue')
const FilingForeignQualification = () => import('./templates/FilingForeignQualification.vue')
const FilingLicensesAndPermits = () => import('./templates/FilingLicensesAndPermits.vue')
const FilingLlcOperatingAgreement = () => import('./templates/FilingLlcOperatingAgreement.vue')
const FilingNameAmendmentOtherAmendments = () => import('./templates/FilingNameAmendmentOtherAmendments.vue')
const FilingReinstatement = () => import('./templates/FilingReinstatement.vue')
const FilingSCorpElection = () => import('./templates/FilingSCorpElection.vue')
const FilingSellersPermit = () => import('./templates/FilingSellersPermit.vue')

const ManageAnnualReport = () => import('./templates/ManageAnnualReport.vue')
const ManageInitialReport = () => import('./templates/ManageInitialReport.vue')
const ManageMinuteManager = () => import('./templates/ManageMinuteManager.vue')
const ManageRegisteredAgent = () => import('./templates/ManageRegisteredAgent.vue')

const RegisterCCorp = () => import('./templates/RegisterCCorp.vue')
const RegisterNonprofitOrganization = () => import('./templates/RegisterNonprofitOrganization.vue')
const RegisterLimitedLiabilityCompany = () => import('./templates/RegisterLimitedLiabilityCompany.vue')
const RegisterLimitedLiabilityPartnership = () => import('./templates/RegisterLimitedLiabilityPartnership.vue')
const RegisterLimitedPartnership = () => import('./templates/RegisterLimitedPartnership.vue')
const RegisterLimitedLiabilityLimitedPartnership = () => import('./templates/RegisterLimitedLiabilityLimitedPartnership.vue')
const RegisterProfessionalLlc = () => import('./templates/RegisterProfessionalLlc.vue')
const RegisterProfessionalCorporation = () => import('./templates/RegisterProfessionalCorporation.vue')
const RegisterSoleProprietorship = () => import('./templates/RegisterSoleProprietorship.vue')
const RegisterSCorp = () => import('./templates/RegisterSCorp.vue')

const NameAvailability = () => import('./templates/NameAvailability.vue')
const NameDoingBusinessAs = () => import('./templates/NameDoingBusinessAs.vue')
const NameDoingBusinessAsRenewal = () => import('./templates/NameDoingBusinessAsRenewal.vue')
const NameReservation = () => import('./templates/NameReservation.vue')

const NonprofitCharitableSolicitationRegistration = () => import('./templates/NonprofitCharitableSolicitationRegistration.vue')

const CopyrightRegistration = () => import('./templates/CopyrightRegistration.vue')
const TrademarkRegistration = () => import('./templates/TrademarkRegistration.vue')
const WebDesignAndDevelopment = () => import('./templates/WebDesignAndDevelopment.vue')

export default {
  components: {
    EinCCorp,
    EinSCorp,
    EinChurchOrganization,
    EinEstateOfDeceased,
    EinTrust,
    EinIndividualSoleProprietorship,
    EinLimitedLiabilityCompany,
    EinPersonalServiceCorporation,
    EinPartnership,
    EinNonProfitOrganization,

    FilingApostilleCertificate,
    FilingCertifiedCopies,
    FilingCertificateOfGoodStanding,
    FilingDigitalCorporateKit,
    FilingDissolution,
    FilingEntityConversion,
    FilingEntityWithdrawal,
    FilingForeignQualification,
    FilingLicensesAndPermits,
    FilingLlcOperatingAgreement,
    FilingNameAmendmentOtherAmendments,
    FilingReinstatement,
    FilingSCorpElection,
    FilingSellersPermit,

    ManageAnnualReport,
    ManageInitialReport,
    ManageMinuteManager,
    ManageRegisteredAgent,

    RegisterCCorp,
    RegisterNonprofitOrganization,
    RegisterLimitedLiabilityCompany,
    RegisterLimitedPartnership,
    RegisterLimitedLiabilityPartnership,
    RegisterLimitedLiabilityLimitedPartnership,
    RegisterProfessionalLlc,
    RegisterProfessionalCorporation,
    RegisterSoleProprietorship,
    RegisterSCorp,

    NameAvailability,
    NameDoingBusinessAs,
    NameDoingBusinessAsRenewal,
    NameReservation,

    NonprofitCharitableSolicitationRegistration,

    CopyrightRegistration,
    TrademarkRegistration,
    WebDesignAndDevelopment
  },
  computed: {
    activeSlug() {
      return store.state.forms.submission.slug
    }
  },
  setup() {
    store.dispatch('forms/fetchSubmission', router.currentRoute.params.id)

    onUnmounted(() => {
      store.commit('forms/RESET_ALL_FORMS_INFO')
    })
  },
}
</script>
